<template>
  <template v-if="props.showAlways || mainMenuStore.leakProfile?.defender_subscription === 'free'" >
    <slot />
    <span class="pro-badge">
      <template v-if="props.invert">{{ mainMenuStore.leakProfile?.defender_subscription === 'free' ? "/FREE" :"/PRO" }}</template>
      <template v-else>{{ mainMenuStore.leakProfile?.defender_subscription === 'free' ? "/PRO" : "/FREE"}}</template>
    </span>
  </template>
  <span v-else><slot /></span>
</template>

<script setup lang="ts">
const mainMenuStore = useMainMenuStore();
const props = withDefaults(defineProps<{
  showAlways?: boolean,
  invert?: boolean,
}>(), {
  showAlways: false,
  invert: false,
});
</script>

<style lang="scss" scoped>
.pro-link {
  pointer-events: auto;
  text-decoration: none;
  color: inherit;
}
.pro-badge {
  color: rgb(var(--v-theme-primary));
  font-weight: 900;
}
</style>
